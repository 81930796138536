import { useCallback } from 'react'

import { Icon } from '@/legacy/components/Icon'

import { useSelectIconEvent } from '@/legacy/core/analytics/hooks'

import { LABEL, LINK_WHATS_BELL } from './constant'
import { Whatsapp } from './styles'

const ChatbotWhatsapp = () => {
  const selectIconEvent = useSelectIconEvent()

  const handleWhatsapp = useCallback(() => {
    selectIconEvent({ itemId: 'serasa-web_web_icone_meio_01', label: 'whatsapp' })
  }, [selectIconEvent])

  return (
    <div>
      <Whatsapp
        role="button"
        href={LINK_WHATS_BELL}
        onClick={handleWhatsapp}
        title={LABEL}
        aria-label={LABEL}
        rel="noopener noreferrer"
        target="_blank"
      >
        <Icon aria-hidden icon="whatsapp" width={40} height={40} />
      </Whatsapp>
    </div>
  )
}
export default ChatbotWhatsapp
