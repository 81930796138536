import styled from '@emotion/styled'

export const Whatsapp = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  max-width: 3.25rem;
  min-width: 2.5rem;
  max-height: 3.25rem;
  min-height: 2.5rem;
  left: 1rem;
  bottom: 4rem;
  background: #009f58;
  border-radius: 50%;
  box-shadow:
    0 0.4rem 0.25rem rgba(0, 0, 0, 0.1),
    0 0.125rem 1rem rgba(0, 0, 0, 0.1);
  z-index: 998;
  cursor: pointer;

  svg {
    width: 3rem;
    height: 3rem;
    z-index: 1;
  }
`
